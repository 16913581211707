import { graphql, Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import TagManager from "../components/TagManager";

const Services = (props) => {
    const services = props.data.services.edges;
    const { intro } = props.data;
    const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute && 'intro-image-absolute'} ${intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'}`;

    return (
        <Layout bodyClass="page-services">
            <TagManager />
            <SEO title="Services" />
            <Helmet>
                <meta
                    name="description"
                    content="Incritech is a group of code artisans focused on building highly scalable and resilient software platforms for the modern web."
                />
            </Helmet>
            <noscript>
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MCXNGPD" height="0" width="0" style={{ display: "none", visibility: "hidden" }}>
                </iframe>
            </noscript>

            <div className="intro">
                <div className="container">
                    <div className="row justify-content-start">
                        <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
                            <div dangerouslySetInnerHTML={{ __html: intro.html }} />
                        </div>
                        {intro.frontmatter.intro_image && (
                            <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                                <img alt={intro.frontmatter.title} className={introImageClasses} src={intro.frontmatter.intro_image} />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="container pb-6">
                <div className="row">
                    {services.map(edge => (
                        <div key={edge.node.id} className="col-12 col-md-4 mb-1">
                            <div className="card service service-teaser">
                                <div className="card-content">
                                    <h2>
                                        <Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
                                    </h2>
                                    <p>{edge.node.excerpt}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
  query ServicesQuery {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services\/.*/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    intro: markdownRemark(fileAbsolutePath: {regex: "/(services.md)/"}) {
      html
      frontmatter {
        title
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
      }
    }
  }
`;

export default Services;
