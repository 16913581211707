// @ts-nocheck
import { useEffect } from "react";

const TagManager = () => {
    useEffect(() => {
        var gtmScript = document.getElementById("gtm-script");
        if (!gtmScript) {
            (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.id="gtm-script";j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MCXNGPD');
        }
    }, []);

    return null;
}

export default TagManager;
